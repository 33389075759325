export const FETCHING_EVENTS = 'FETCHING_EVENTS';
export const FETCHING_EVENTS_RESOLVED = 'FETCHING_EVENTS_RESOLVED';
export const FETCHING_EVENTS_REJECTED = 'FETCHING_EVENTS_REJECTED';
export const FETCHING_EVENT = 'FETCHING_EVENT';
export const FETCHING_EVENT_RESOLVED = 'FETCHING_EVENT_RESOLVED';
export const FETCHING_EVENT_REJECTED = 'FETCHING_EVENT_REJECTED';
export const ADDING_FAVORITES = 'ADDING_FAVORITES';
export const ADDING_FAVORITES_RESOLVED = 'ADDING_FAVORITES_RESOLVED';
export const ADDING_FAVORITES_REJECTED = 'ADDING_FAVORITES_REJECTED';
export const REMOVING_FAVORITES = 'REMOVING_FAVORITES';
export const REMOVING_FAVORITES_RESOLVED = 'REMOVING_FAVORITES_RESOLVED';
export const REMOVING_FAVORITES_REJECTED = 'REMOVING_FAVORITES_REJECTED';
