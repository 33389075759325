import React from 'react';

const Footer = () => (
  <footer>
    <p>
      &copy; MoshPit Events Inc.  2018 -
      {' '}
      {new Date().getFullYear()}
    </p>
  </footer>
);

export default Footer;
